<template>
  <div>
    <div style="display: flex; justify-content: center; margin-block: 1rem">
      <el-date-picker
        size="mini"
        v-model="selectDate"
        type="daterange"
        range-separator="To"
        start-placeholder="Start date"
        end-placeholder="End date"></el-date-picker>
    </div>

    <el-tabs v-model="tabsVModel">
      <el-tab-pane label="Overview" name="overview">
        <reportChart :dateTime="formattedDate" />
      </el-tab-pane>
      <el-tab-pane label="SMS Reports" name="sms">
        <CampaignReportTable reportType="sms" :dateTime="formattedDate" />
      </el-tab-pane>
      <el-tab-pane label="Voice Reports" name="voice">
        <CampaignReportTable reportType="voice" :dateTime="formattedDate" />
      </el-tab-pane>
      <el-tab-pane label="Sender IDs" name="sender_ids">
        <senderidTable
          :parent="properties"
          :theoBotMode="true"
          :dateTimeTheoBot="formattedDate"
          class="sender-id-table"></senderidTable>
      </el-tab-pane>
      <el-tab-pane label="Wallet History" name="wallet_history">
        <WalletHistory :dateTime="formattedDate" />
      </el-tab-pane>
      <el-tab-pane label="Bundle History" name="bundle_history">
        <BundleHistory :dateTime="formattedDate" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
  import CampaignReportTable from "./CampaignReportTable.vue";
  import senderidTable from "../../senderIDs/senderidTable.vue";
  import WalletHistory from "./WalletHistory.vue";
  import BundleHistory from "./BundleHistory.vue";
  import reportChart from "./reportChart.vue";

  export default {
    name: "theobot",
    components: {
      CampaignReportTable,
      senderidTable,
      WalletHistory,
      BundleHistory,
      reportChart,
    },
    data() {
      return {
        tabsVModel: "overview",
        properties: {
          name: "",
          storeDataName: "fetchTheoBotSenderIDs",
          from: "",
          status: "",
        },
        selectDate: [],
      };
    },
    computed: {
      formattedDate() {
        return this.selectDate.map((date) => {
          return new Date(date).toISOString().split("T")[0];
        });
      },
    },
    mounted() {
      this.selectDate = [
        new Date(new Date().setDate(new Date().getDate() - 7)),
        new Date(),
      ];
    },
  };
</script>

<style scoped>
  .sender-id-table::v-deep .el-table {
    height: 40vh !important;
  }

  >>> .el-range-separator {
    margin-inline: 1rem !important;
  }
</style>
