<template>
  <div>
    <div
      style="
        display: flex;
        justify-content: center;
        width: 100%;
        margin-block: 1rem;
      ">
      <el-select
        v-model="selectedType"
        placeholder="Filter By Type"
        @change="fetchReports(1)"
        size="small">
        <el-option
          v-for="item in availableTypesComp"
          :key="item.value"
          :label="item.label"
          :value="item.value"></el-option>
      </el-select>
    </div>
    <el-table
      height="43vh"
      :data="compTableData"
      v-loading="tableLoading"
      size="small">
      <div v-for="(column, index) in availableColumnsComp">
        <!-- Add conditionals here -->
        <el-table-column
          :label="column.replaceAll('_', ' ')"
          :prop="column"
          :key="index"
          v-if="column == 'message' && reportType == 'sms'">
          <template slot-scope="scope"
            ><div>
              <el-popover
                trigger="hover"
                :content="scope.row.message"
                v-if="scope.row.message.length > 15">
                <span slot="reference"
                  >{{ scope.row.message.slice(0, 15) }}...</span
                >
              </el-popover>
              <span v-else>{{ scope.row.message }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :label="column.replaceAll('_', ' ')"
          :prop="column"
          :key="index"
          v-else-if="column == 'campaign_name'">
          <template slot-scope="scope"
            ><div>
              <el-popover
                trigger="hover"
                :content="scope.row.campaign_name"
                v-if="scope.row.campaign_name.length > 15">
                <span slot="reference">
                  {{ scope.row.campaign_name.slice(0, 15) }}...
                </span>
              </el-popover>
              <span v-else>{{ scope.row.campaign_name }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :label="column.replaceAll('_', ' ')"
          :prop="column"
          :key="index"
          v-else-if="column == 'message_type'">
          <template slot-scope="scope">
            <div>
              <el-tag size="small" effect="dark" :type="manageTagColors">{{
                scope.row.message_type
              }}</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="Date & Time"
          :prop="column"
          :key="index"
          v-else-if="column == 'created_at'">
          <template slot-scope="scope">
            <div>{{ moment(scope.row.created_at).format("ll hh:mm A") }}</div>
          </template>
        </el-table-column>
        <el-table-column
          v-else
          :label="column.replaceAll('_', ' ')"
          :prop="column"
          :key="index"></el-table-column>
      </div>
    </el-table>
    <div class="col-md-12" style="text-align: center; margin-top: 2em">
      <el-pagination
        background
        layout="prev, pager, next"
        @current-change="handlePageChange"
        :page-size="pageSize"
        :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
  /**
   * @typedef {{current_page: number, data: Array, first_page_url: string, from: number, last_page: number, last_page_url: string, next_page_url: string, path: string, per_page: number, prev_page_url: string, to: number, total: number}}  dataWithPagination
   */

  import moment from "moment";
  export default {
    name: "sms_reports_theobot",
    props: {
      reportType: {
        type: String,
        required: true,
        validator(value) {
          return ["sms", "voice"].includes(value.toLowerCase());
        },
      },
      dateTime: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        /** @type {dataWithPagination} */
        tableData: {},
        pageSize: 50,
        page: 1,
        tableLoading: false,
        availableTypes: [
          {
            label: "Quick SMS",
            value: "quicksms",
          },
          {
            label: "Group SMS",
            value: "groupsms",
          },
          {
            label: "Excel SMS",
            value: "excelsms",
          },
          {
            label: "Quick Voice",
            value: "quickvoice",
          },
          {
            label: "Group Voice",
            value: "excelvoice",
          },
          {
            label: "Excel Voice",
            value: "groupvoice",
          },
        ],
        availableColumns: [
          "id",
          "user_id",
          "campaign_id",
          "route_name",
          "message",
          "campaign_name",
          "sender_id",
          "total_recipients",
          "total_sms_used",
          "total_voice_used",
          "wallet_charge",
          "created_at",
          "message_type",
        ],
        selectedType:
          this.$props.reportType === "sms" ? "quicksms" : "quickvoice",
      };
    },
    computed: {
      availableTypesComp() {
        return this.availableTypes.filter((item) => {
          return item.label
            .split(" ")[1]
            .toLowerCase()
            .includes(this.$props.reportType.toLowerCase());
        });
      },
      /** @returns {dataWithPagination['data']} */
      compTableData() {
        return this.tableData.data;
      },
      /** @returns {dataWithPagination['total']} */
      total() {
        return this.tableData.total;
      },
      availableColumnsComp() {
        return this.availableColumns.filter((item) => {
          if (
            (item == "total_sms_used" ||
              item == "message" ||
              item == "sender_id") &&
            this.$props.reportType === "voice"
          ) {
            return false;
          }
          if (item == "total_voice_used" && this.$props.reportType === "sms") {
            return false;
          }
          if (item == "id" || item == "user_id" || item == "campaign_id") {
            return false;
          }

          return true;
        });
      },
      manageTagColors() {
        if (this.selectedType.includes("quick")) {
          return "warning";
        }
        if (this.selectedType.includes("group")) {
          return "danger";
        }
        if (this.selectedType.includes("excel")) {
          return "success";
        }
      },
    },
    methods: {
      onCellClick(row) {
        this.$router
          .push({
            name: "Theo Bot Details",
            params: { id: row.campaign_id },
          })
          .catch(() => {});
      },
      async fetchReports(page = 1) {
        try {
          this.tableLoading = true;
          this.tableData = await this.$store.dispatch(
            "admin/reporting/fetchTheoBotReports",
            {
              report: this.selectedType,
              page: page,
              date: this.$props.dateTime,
            }
          );
        } catch (error) {
          this.$message.error(error.message);
        } finally {
          this.tableLoading = false;
        }
      },
      handlePageChange(val) {
        this.page = val;
        this.fetchReports(val);
      },
    },
    watch: {
      dateTime: {
        handler() {
          this.fetchReports(this.page);
        },
      },
    },
  };
</script>

<style scoped>
  .el-table >>> * {
    text-transform: capitalize;
  }
</style>
