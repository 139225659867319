<template>
  <div>
    <div class="search-container">
      <span
        ><el-input
          v-model="search"
          size="large"
          :placeholder="$t('type-to-search')"
          clearable
      /></span>
    </div>
    <div class="table-container">
      <el-table
        height="38vh"
        :data="filteredTable"
        v-loading="tableLoadingData"
        size="small">
        <!-- Transaction ID -->
        <el-table-column :label="$t('transaction-id')" width="200px" prop="ref_id">
        </el-table-column>
        <!-- Payment Date -->
        <el-table-column :label="$t('payment-date')" width="200px" prop="payment_date">
        </el-table-column>
        <!-- Amount -->
        <el-table-column :label="$t('dashboard.wallet.amount')" width="200px">
          <template slot-scope="scope">
            {{
              scope.row.amount.toLocaleString("en-US", {
                style: "currency",
                currency: "GHC",
              }) || 0
            }}
          </template>
        </el-table-column>

        <!-- Balance Before Deposit -->
        <el-table-column :label="$t('balance-before-deposit')" width="200px">
          <template slot-scope="scope">
            {{ processAmount(scope.row.balance_before_deposit) }}
          </template>
        </el-table-column>
        <!-- Momo Number -->
        <el-table-column :label="$t('account-number')" width="200px" prop="momo_num">
          <template slot-scope="scope">
            {{ scope.row.momo_num }}
          </template>
        </el-table-column>
        <!-- Status -->
        <el-table-column :label="$t('dashboard.send_message.international_messages.status')" width="200px">
          <template slot-scope="scope">
            <p
              style="margin: 0 auto; color: #31cd54"
              v-if="scope.row.status == 1">
              SUCCESS
            </p>
            <p
              style="margin: 0 auto; color: #ff0066"
              v-else-if="scope.row.status == 2">
              FAILED
            </p>
            <p
              style="margin: 0 auto; color: #ef9e14"
              v-else="scope.row.status == 0">
              PENDING
            </p>
          </template>
        </el-table-column>
        <!-- Payment Method -->
        <el-table-column
          :label="$t('payment-method')"
          width="200px"
          prop="payment_method">
        </el-table-column>
        <!-- Top Up By -->
        <el-table-column :label="$t('top-up-by')" width="200px" prop="top_up_by">
          <template slot-scope="scope">
            {{ scope.row.top_up_by.toString() }}
          </template>
        </el-table-column>
        <!-- Action -->
        <el-table-column :label="$t('misc.table.actions')" width="200px">
          <template slot-scope="scope">
            <button
              type="button"
              style="margin: 0 auto; color: #31cd54"
              class="complete-payment-icon"
              v-if="scope.row.status === 0">
              <CompletePayment
                @click.native="checkPayment({ transaction_id: scope.row })" />
            </button>
          </template>
        </el-table-column>
      </el-table>
      <div class="" style="text-align: center; margin-top: 2em">
        <el-pagination
          background
          layout="prev, pager, next"
          @current-change="handleCurrentChange"
          :page-size="pageSize"
          :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
  import store from "@/state/store.js";
  import CompletePayment from "./CompletePayment.vue";
  import downloadcsv from "@/helpers/downloadcsv";
  export default {
    data() {
      return {
        depositHistory: [],
        page: 1,
        pageSize: 10,
        total: 0,
        search: "",
        loading: false,
        button_loading: false,
        tableLoadingData: false,
      };
    },
    components: {
      CompletePayment,
    },
    methods: {
      handleCurrentChange(val) {
        this.page = val;
        this.refetchHistory();
      },
      exportWalletHistory() {
        // this.exportLoading = true
        this.button_loading = true;
        store
          .dispatch("wallet/exportWalletHistory", this.$route.params.id)
          .then((data) => {
            downloadcsv(
              data,
              `${localStorage
                .getItem("userName")
                .replace(/ /g, "_")}_Wallet_History_.csv`
            );
            this.button_loading = false;
          })
          .finally(() => (this.button_loading = false));
      },
      checkPayment({ transaction_id }) {
        this.tableLoadingData = true;
        let payload = {
          transaction_id: transaction_id.ref_id,
        };
        store
          .dispatch("wallet/checkPaymentStatus", payload)
          .then((data) => {
            this.$notify({
              message: this.$t('payment-completed'),
              type: "success",
            });
          })
          .catch((err) => {
            this.$notify({
              message: this.$t('payment-failed'),
              type: "error",
            });
          })
          .finally(() => this.refetchHistory());
      },
      refetchHistory() {
        this.tableLoadingData = true;
        store
          .dispatch("admin/reporting/fetchTheoBotWalletHistory", {
            page: this.page,
            date: this.$props.dateTime,
          })
          .then((response) => {
            this.depositHistory = response.data;
            this.tableLoadingData = false;
          });
      },
      processAmount(amount) {
        const parsedAmount = Number(amount);
        const language = store.getters["language/currentLanguage"];
        return parsedAmount.toLocaleString(
          language == "fr" ? "fr-CI" : "en-US",
          {
            style: "currency",
            currency: language == "fr" ? "CFA" : "GHS",
          }
        );
      },
    },
    computed: {
      filteredTable() {
        return this.depositHistory.filter((item) => {
          return (
            item.ref_id.toLowerCase().includes(this.search.toLowerCase()) ||
            item.payment_date
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            item.amount
              .toLocaleString("en-US", { style: "currency", currency: "GHC" })
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            item.momo_num.toLowerCase().includes(this.search.toLowerCase()) ||
            item.payment_method
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            item.balance_before_deposit
              .toLocaleString("en-US", { style: "currency", currency: "GHC" })
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            item.top_up_by
              .toString()
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            item.status_message
              .toLowerCase()
              .includes(this.search.toLowerCase())
          );
        });
      },
      dynamicHeight() {
        if (screen.height < 749) {
          return "50vh";
        } else {
          return "55vh";
        }
      },
    },
    props: {
      dateTime: {
        type: Array,
        default: () => [],
      },
    },
    watch: {
      dateTime: {
        handler() {
          this.refetchHistory();
        },
        deep: true,
      },
    },
  };
</script>
<style scoped>
  .table-container,
  .search-container {
    margin-top: 2rem;
  }

  .complete-payment-icon {
    border: none;
  }

  .search-container {
    display: flex;
    justify-content: space-between;
  }

  .export-button {
    padding: 0.2rem 2rem;
    border-radius: 5px;
    border: 1px solid transparent;
    background-color: #f7921c;
    color: #fff;
  }

  input {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 0.3rem;
    width: 15rem;
  }

  button:disabled {
    background-color: #d8d6d6;
  }

  button:hover:disabled {
    background-color: #d8d6d6;
    color: white;
    border-color: transparent;
  }
</style>
